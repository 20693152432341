import './footer.css'
export const Footer = () => {
    return (
        <footer>
            <div className="footer-icons">
                <a href="https://github.com/Captain-DarkO" className="fa typcn typcn-social-github"></a>
                {/* <a href="https://www.facebook.com/Anurag.khadtkar" className="fa typcn typcn-social-facebook"></a> */}
                <a href="https://www.linkedin.com/in/anurag-khadatkar" className="fa typcn typcn-social-linkedin"></a>
                <a href="https://twitter.com/Anurag2o_OK" className="fa typcn typcn-social-twitter"></a>
            </div>
            <div className="footer-line">
                <div className="tickr">© 2023 Anurag K.</div>
            </div>
        </footer>
    )
};