import './App.css';
import { Footer } from './Footer';
import { Background } from './background/background';
import { Firebase } from './components/firebase/Firebase';

function App() {
  return (
    
    <div className="App">
      <Firebase/>
      {/* <Background/> */}
      <header className="App-header">
        <div className="main-container">
        <h3>Hi There! </h3>
        <p>
        I am Anurag! <br/>
          I am a Full-Stack Software Engineer.<br/>
          Happy to conect with you :
        </p>
        </div>
        
      </header>
      <Footer/>
    </div>
  );
}

export default App;
