// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import React, { useEffect } from 'react';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDIs9rb5uW5aKi5o88xNLfym8BC_S3v3S4",
    authDomain: "arena20-2cbe2.firebaseapp.com",
    databaseURL: "https://arena20-2cbe2.firebaseio.com",
    projectId: "arena20-2cbe2",
    storageBucket: "arena20-2cbe2.appspot.com",
    messagingSenderId: "628438211051",
    appId: "1:628438211051:web:dba393cdf60cce421461ae",
    measurementId: "G-BJ65N3KK4W"
};

// Initialize Firebase


export const Firebase = () => {

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
    }, []);

    return (
        <></>
    )
};